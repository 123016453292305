import React, { useEffect } from "react";
import stepWhitsundaysChef from "../../assets/STEP-Whitsundays.png";
import stepWhitsundaysHosp from "../../assets/STEP-WhitsundaysHosp.png";
import stepNursing from "../../assets/STEP-NursingAgeCare.png";
import "./Options.css";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { CgOptions } from "react-icons/cg";

const Options = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="options">
      <div className="container u-text u-text-light" data-aos="fade-up">
        <div className="u-title">
          <CgOptions color="orangered" size={30} />
          <h2>Study Options and Special Programs</h2>
          <p>
            We have literally hundreds of study options available and some
            Special Programs. Our special programs are complete packages: we
            have already found the accommodation and employers - oh and of
            course the best place to study!
          </p>
        </div>
        <div id="options-content">
          <div id="studyOptions">
            <h3>Some of our Study Options</h3>
            <ul id="studyList">
              <li className="listHeading">Business and IT</li>
              <li>Diploma of Accounting</li>
              <li>Diploma of Business</li>
              <li>Diploma of Leadership and Management</li>
              <li>Diploma of Marketing and Communication</li>
              <li>Diploma of Information Technology</li>
              <li>Certificate IV in Cyber Security</li>
              <li className="listHeading">Creative </li>
              <li>Diploma of Graphic Design</li>
              <li>Diploma of Photography and Digital Imaging</li>
              <li>Diploma of Screen and Media</li>
              <li>Diploma of Visual Arts</li>
              <li className="listHeading">Education and community</li>
              <li>Diploma of Early Childhood Education and Care</li>
              <li>Certificate IV in School Based Education Support</li>
              <li>Certificate III in Individual Support</li>
              <li>Diploma of Community Services</li>
              <li>Diploma of Mental Health</li>
              <li className="listHeading">English</li>
              <li>General English</li>
              <li>IELTS Preparation</li>
              <li>English for Academic Purposes (EAP)</li>
              <li>Cambridge Preparation FCE and CAE</li>
              <li className="listHeading">Environment and animal services</li>
              <li>Diploma of Horticulture</li>
              <li>Diploma of Landscape Design</li>
              <li>Diploma of Agribusiness Management</li>
              <li>Diploma of Agriculture</li>
              <li>Diploma of Conservation and Ecosystem Management</li>
              <li>Certificate III in Animal Care Services</li>
              <li>Certificate IV in Veterinary Nursing</li>
              <li className="listHeading">Health and science</li>
              <li>Diploma of Dental Technology</li>
              <li>Bachelor of Dental Prosthetics</li>
              <li>Diploma of Anaesthetic Technology</li>
              <li>Diploma of Laboratory Technology</li>
              <li>Diploma of Nursing</li>
              <li>Diploma of Remedial Massage</li>
              <li>Diploma of Sport (Coaching)</li>
              <li className="listHeading">Service industries</li>
              <li>Certificate III in Hairdressing</li>
              <li>Diploma of Beauty Therapy</li>
              <li>Certificate IV in Commercial Cookery</li>
              <li>Diploma of Hospitality Management</li>
              <li>Certificate III in Patisserie</li>
              <li>Diploma of Event Management</li>
              <li>Certificate III in Travel</li>
              <li>Diploma of Travel and Tourism Management</li>
              <li className="listHeading">Trades</li>
              <li>
                Certificate III in Heavy Commercial Vehicle Mechanical
                Technology
              </li>
              <li>Certificate III in Light Vehicle Mechanical Technology</li>
              <li>Certificate IV in Automotive Management</li>
              <li>Certificate IV in Automotive Mechanical Diagnosis</li>
              <li>Certificate IV in Automotive Mechanical Overhauling</li>
              <li>Certificate III in Cabinet Making</li>
              <li>Certificate III in Carpentry</li>
              <li>Associate Degree in Civil Engineering</li>
              <li>Advanced Diploma of Engineering Technology - Electrical</li>
              <li>Diploma of Electronics and Communications Engineering</li>
              <li>
                Advanced Diploma of Electronics and Communications Engineering
              </li>
              <li>Diploma of Engineering - Technical</li>
              <li>Advanced Diploma of Engineering</li>
            </ul>
          </div>
          <div id="specialPrograms">
            <h3>Special Programs</h3>

            <div className="card">
              <img
                src={stepWhitsundaysChef}
                alt="Whitsundays Chef"
                aria-hidden="true"
              />
              <div className="cardContainer">
                <h4>Whitsundays - Chef</h4>
                <p>
                  Do you want to be a professional chef? This program is a dual
                  certificate program. Over 2 years you will attain a
                  Certificate IV Commercial Cookery and a Diploma Hospitality
                  Management. What's more you will work in a world class kitchen
                  at one of the premier holiday destinations in the world. Oh
                  and you get to live there as well!
                </p>
                <div className="cardButton">
                  <a
                    href={`https://step-whitsunday.speedy.global/`}
                    className={`btn btn-dark`}
                    target={`_blank`}
                  >
                    Learn More ...
                  </a>
                </div>
              </div>
            </div>
            <div className="card">
              <img
                src={stepWhitsundaysHosp}
                alt="Whitsundays Hospitality"
                aria-hidden="true"
              />
              <div className="cardContainer">
                <h4>Whitsundays - Hospitality</h4>
                <p>
                  Do you want to be a professional hospitality worker? You can
                  study, work and live in one of the most beautiful places on
                  earth. This premium international destination will give you
                  the education and experience you need to work anywhere.
                </p>
                <div className="cardButton">
                  <a
                    href={`https://step-whitsunday.speedy.global/`}
                    className={`btn btn-dark`}
                    target={`_blank`}
                  >
                    Learn More ...
                  </a>
                </div>
              </div>
            </div>
            <div className="card">
              <img
                src={stepNursing}
                alt="Age Care and Nursing in Australia"
                aria-hidden="true"
              />
              <div className="cardContainer">
                <h4>Nursing - Age Care</h4>
                <p>
                  Australia has a major shortage of Nurses and the Age Care
                  sector is despirately short of professionals. If you have a
                  caring nature and want to be a professional in the field of
                  nursing then this is the course for you.
                </p>
                <div className="cardButton">
                  <Button
                    text={"Learn More ..."}
                    btnClass={"btn-dark"}
                    href={"#subscribe"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Options;
