import React, { useEffect } from "react";
import AOS from "aos";
import "./Colleges.css";
import "aos/dist/aos.css";
import { BsBook } from "react-icons/bs";
import stepCollegesTAFEImg from "../../assets/STEP-Colleges-TAFE.png";
import stepCollegesGoLearnImg from "../../assets/STEP-Colleges-Go-Learn.png";
import stepCollegesAIASImg from "../../assets/STEP-Colleges-AIAS.png";

const Colleges = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="colleges">
      <div className="container">
        <div className="u-title u-text">
          <BsBook color="orangered" size={30} />
          <h2>Colleges</h2>
          <p>
            To ensure STEP particpants have the best possible future we only
            work with colleges who provide the best possible standard of fully
            accredited and recognised training.
          </p>
        </div>
        <hr className="outer-sep" />
        <div id="colleges-tafe-main" className="u-text u-text-light">
          <div id="colleges-tafe-left" data-aos="fade-right">
            <div id="tafe">
              <p>
                TAFE Queensland is Queensland's largest and most experienced
                training provider. They deliver practical, industry-relevant
                training across a range of industries from entry-level
                certificates to bachelor degrees, at more than 50 locations in
                Queensland.
              </p>
              <p>
                Whether you want to enhance your English language skills, get
                trade qualified, or complete a professional-level qualification,
                TAFE Queensland have the courses for you. TAFE Queensland
                certification is a great way to enhance your career and get you
                into a world class job through Speedy Global.
              </p>
              <p>
                Speedy Global has several special STEP Programs with TAFE
                Queensland. These special programs have already been developed
                for a defined cohort and include high quality employment and
                accommodation.
              </p>
            </div>
          </div>
          <div id="colleges-tafe-right" data-aos="fade-left">
            <img
              src={stepCollegesTAFEImg}
              alt="TAFE Queensland"
              aria-hidden="true"
            />
          </div>
        </div>
        <hr id="college-sep" />
        <div id="colleges-go-learn-main" className="u-text u-text-light">
          <div id="colleges-go-learn-left" data-aos="fade-right">
            <img
              src={stepCollegesGoLearnImg}
              alt="Go Learn Australia"
              aria-hidden="true"
            />
          </div>
          <div id="colleges-go-learn-right" data-aos="fade-left">
            <div id="go-learn">
              <p>
                Go Learn Australia team has more than 25+ years combined
                experience in education and student services, specifically
                within the Australia and New Zealand education market. Since its
                inception in 2013 it been connecting Australian domestic and
                international students to genuine and respected vocational
                education and training providers and Universities.
              </p>
              <p>
                Speedy Global is proud to team up with Go Learn Australia so we
                can provide applicants with a huge range of quality courses all
                over Australia. It does not matter what a person wants to learn,
                if it is taught in Australia under an Australian Study Visa, we
                almost certainly can provide it. Speedy Gobal will assist with
                accommodation and employment for all our students.
              </p>
              <p>
                Speedy Global continues to develop special STEP programs with Go
                Learn Australia that ensure quality education, work and fun.
                These programs are offered all over Australia; ask us for more
                information.
              </p>
            </div>
          </div>
        </div>
        <hr id="college-sep" />
        <div id="colleges-aias-main" className="u-text u-text-light">
          <div id="colleges-aias-left" data-aos="fade-right">
            <div id="aias">
              <p>
                AIAS, or the Australian Institute of Advanced Studies, is an
                educational institution located in Brisbane, Australia. They
                have been operating since 2014 and their directors have over 50
                years of combined experience in the vocational education sector.
                They offer courses in fields such as Engineering, Hospitality,
                Design & Media, Age Care, Information Technology, and Business.
              </p>
              <p>
                The institution emphasises on practical learning, with
                interactive and easily consumable course materials, engaging
                workshops and assessments that help students develop their
                skills and knowledge. They also work on building students'
                self-confidence through practical workshops. The institution
                also has strong ties with employers in the engineering sector,
                which helps students with a direct pathway to employment.
              </p>
              <p>
                AIAS values its students and community and aims to provide
                confident, efficient and safe professionals who contribute
                positively to the community. They celebrate student success and
                invite students to join them on an enjoyable educational
                journey. They believe that if students aren't confident in their
                knowledge and skills when they enter the workforce, it may prove
                detrimental to their professional work life and possibly affect
                their community.
              </p>
            </div>
          </div>
          <div id="colleges-aias-right" data-aos="fade-left">
            <img
              src={stepCollegesAIASImg}
              alt="Australian Institute of Advanced Studies"
              aria-hidden="true"
            />
          </div>
        </div>
        <hr className="outer-sep" />
      </div>
    </section>
  );
};

export default Colleges;
