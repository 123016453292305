import { SiFuturelearn } from "react-icons/si";
import { MdAttachMoney, MdSupportAgent } from "react-icons/md";
import { IoMdSchool } from "react-icons/io";

export const FeatureList = [
	{
		id: 1,
		icon: <IoMdSchool color="#0a1930" size={22} />,
		heading: "Internationally Recognised",
		text: "You will study in Australia with a CRICOS provider. Your certificate is issued in Australia and is internationally recognised.",
	},
	{
		id: 2,
		icon: <MdAttachMoney color="#0a1930" size={22} />,
		heading: "Full Australian Salary",
		text: "You will be fully paid, just like an Australian, for all your work. You will gain valuable experience without having to sacrifice your income. See our FAQ for more details.",
	},
	{
		id: 3,
		icon: <MdSupportAgent color="#0a1930" size={22} />,
		heading: "Complete Support",
		text: "From the time you arrive we will be here for you. We make sure you are safe, have a place to live and get a good job. Just think of us 'Uncle'.",
	},
	{
		id: 4,
		icon: <SiFuturelearn color="#0a1930" size={22} />,
		heading: "Your Future Success",
		text: "After your study we can help you get work on a cruise ship or in another country or, if you want to stay, we will get you work in Australia.",
	},
];
