import React, { useEffect } from "react";
import AOS from "aos";
import "./Visa.css";
import "aos/dist/aos.css";
import { HiOutlineDocument } from "react-icons/hi";
import stepVisaImg from "../../assets/STEP-Visa.png";

const Visa = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="visa">
      <div className="container">
        <div className="u-title u-text">
          <HiOutlineDocument color="orangered" size={30} />
          <h2>Visa</h2>
          <p>
            Speedy Global is proud to partner with Milestone Migration (MARN
            0801338), one of the most respected migration agents in Australia,
            to provide visa's for all of our STEP participants.
          </p>
        </div>
        <div id="visa-main" className="u-text u-text-dark">
          <div id="visa-left" data-aos="fade-right">
            <p>
              Milestone Migration provides professional and affordable migration
              services to businesses and individuals. Having been involved in
              helping over 4000 migrants call Australia home, their team is an
              experienced provider who understands how important a successful
              migration outcome is for your future. As registered agents, they
              adhere to the Migration Agents Code of Conduct so you can be
              certain you are in competent and trustworthy hands.
            </p>
            <p>
              After Speedy Global has prepared the candidates and found the
              right study program, identified good employers and ensured the
              availability of safe accommodation: Milestone Migration reviews
              all the paperwork, consults directly with the candidates and
              submits the application for every Australian Student Visa required
              for STEP participants. Importantly, Speedy Global and Milestone
              Migration will continue to work with all STEP participants after
              they graduate to help them stay in Australia if they wish.
            </p>
          </div>
          <div id="visa-right" data-aos="fade-left">
            <img
              src={stepVisaImg}
              alt="Milestone Migration"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Visa;
