import React, { useEffect } from "react";
import AOS from "aos";
import Button from "../UI/Button/Button";
import { BsMouse } from "react-icons/bs";

import stepHeaderImg from "../../assets/STEP-General.png";

import "./Header.css";
import "../UI/Button/Button.css";
import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="header">
      <div className="container">
        <div id="header-main" className="u-text u-text-light">
          <div id="header-left" data-aos="fade-right">
            <h1>
              Learn, Work &amp; Fun
              <div id="header-subtitle">
                <span>S</span>tudy <span>T</span>raining <span>E</span>
                mployment <span>P</span>lay
              </div>
            </h1>
            <p>
              Study Training Employment Play, STEP, is a special Australian
              study and work program for people to get quality education, work
              in their chosen field, and have a lot fun.
            </p>
            <p>
              You will study for a minimum of 2 years in one of the best
              countries in the world. You will earn the money you need to pay
              for your study, live in Australia, support your family and have
              fun. After your study you will have a world class education,
              extensive experience in your field and a real pathway to a
              successful future!
            </p>
            <h2>
              <i>
                <span>&quot;</span>Get fully paid and experience while you get a
                world class education!<span>&quot;</span>
              </i>
            </h2>
            <div id="header-cta">
              <Button
                text={"Get Started"}
                btnClass={"btn-dark"}
                href={"#subscribe"}
              />
              <Button
                text={"Career Options List"}
                btnClass={"btn-light"}
                href={"#options"}
              />
            </div>
          </div>
          <div id="header-right" data-aos="fade-left">
            <img
              src={stepHeaderImg}
              alt="Study Training Employment Play - General Image"
              aria-hidden="true"
            />
          </div>
        </div>
        <div id="floating-icon">
          <a href="#features">
            <BsMouse color="#fff" size={25} id="mouse" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Header;
